<!--
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-04-17 09:57:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\service\dialog\statusDialog.vue
 -->
<template>
  <div class="dialog-container">
    <el-dialog
      :visible.sync="show"
      @open="openDialog"
      class="all-dialog"
      :before-close="closeDialog"
      center
      append-to-body
      width="400px">
      <div class="dialog-wrap">
        <i class="el-icon-warning"></i>
        <p v-show="flag">{{$t('warning1')}}</p>
        <p v-show="!flag">{{$t('warning2')}}</p>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    flag: {
      required: true, // true为上架
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      id: [],
    }
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      console.log('我初始化了')
      this.id = []
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      this.$http.post(apis.modifyStrtus, { id: this.id, status: this.flag ? '1' : '0' }).then((res) => {
        if (res.data.code === 0) {
          this.closeDialog()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import url('../../../../../src/assets/styles/mixin.scoped.less');
.dialog-wrap{
  display: grid;
  justify-content: center;
  .el-icon-warning{
    font-size: 50px;
    margin: 0 auto;
    color: @confirmDialog-icon-color;
  }
  p{
    line-height: 40px;
  }
}
</style>
