<template>
  <div class="dialog-container">
    <el-dialog
      title="入场时间设置"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="480px">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="24" v-for="(item, index) in sportItemsList" :key="index">
            <el-form-item :label="item.sportName" :prop="item.sportName">
              <el-input v-model="item.advenceTime" style="width: 100%" @input="e => item.advenceTime = e <= 200 && e >= 0? e : 200" type="number">
                <template slot="prepend">提前</template>
                <template slot="append">分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="离开时间控制">
            <el-form label-width="80px">
              <el-radio-group v-model="leaveType" class="allType">
                <div class="leaveTimeRadio">
                  <el-radio label="NORMAL" class="typeStyle">不控制</el-radio>
                </div>
                <div class="leaveTimeRadio">
                  <el-radio label="ENTER_TOTAL_TIME" class="typeStyle">入场总时长</el-radio>
                  <div v-if="leaveType === 'ENTER_TOTAL_TIME'">
                    <el-form-item :label="item.sportName" v-for="(item, index) in sportItemsList" :key="index" >
                      <el-input-number size="mini" v-model="item.leaveTime"></el-input-number>
                    </el-form-item>
                  </div>
                </div>
                <div class="leaveTimeRadio">
                  <el-radio label="ORDER_OVER_DELAY" class="typeStyle">订单结束后延迟</el-radio>
                  <div v-if="leaveType === 'ORDER_OVER_DELAY'">
                    <el-form-item :label="item.sportName" v-for="(item, index) in sportItemsList" :key="index">
                      <el-input-number size="mini" v-model="item.leaveTime"></el-input-number>
                    </el-form-item>
                  </div>
                </div>
              </el-radio-group>
            </el-form>
          </el-form-item>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      sportItemsList: [],
      leaveType: '',
      num: 1,
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', 'venueId']),
    apis() {
      return apis
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.form = new AddDialogClass('form')
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // console.log(this.leaveType);
      this.sportItemsList.forEach((item) => {
        item.leaveType = this.leaveType
      });
      const params = {
        listAdvenceTime: this.sportItemsList,
      }
      console.log(params);
      this.$http.post(apis.updateAdvenceTime, params).then((res) => {
        if (res.data.code === 0) {
          this.sportItemsList = res.data.data
          this.$message.success("保存成功")
          this.closeDialog()
        }
      })
    },
    /**
     * @function 查询数据
     */
    getQueryData() {
      this.$http.get(`${apis.getAdvenceTime}?venueId=${this.venueId}`).then((res) => {
        if (res.data.code === 0) {
          this.sportItemsList = res.data.data
          this.leaveType = res.data.data[0].leaveType
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.allType{
  // padding-top: 15px;
  .leaveTimeRadio{
    // height: 40px;
    width: 340px;
    display: flex;
    justify-content: space-between;
    .typeStyle{
      height: 40px;
      line-height: 40px;
      margin-right: 0px
    }
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
