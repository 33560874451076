/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-04-10 15:30:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\service\component\bookingPartClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.status = '1'
      this.reservateFlag = 'N'
      this.cancelReservateFlag = 'Y'
      this.reservateInterval = ''
      this.flag = false
      this.businessHours = []
      this.businessHoursMessage = ''
      this.dates = []
      this.weeks = []
      this.week = []
      this.refundAdvenceTime = 24
      this.refundAmountProportion = 100
      this.refundLimit = 1
    } else if (type === 'rule') {
      this.reservateFlag = [
        {
          required: true,
          trigger: 'change',
          message: `${window.v.$t('QXZ')} ${window.v.$t('bookingSetting')}`,
        },
      ]
      this.businessHours = [
        {
          required: true,
          type: 'array',
          trigger: 'change',
          message: `${window.v.$t('QXZ')} ${window.v.$t('defaultTime')}`,
        },
      ]
      this.reservateInterval = [
        {
          required: true,
          trigger: 'change',
          message: `${window.v.$t('QXZ')} ${window.v.$t('bookingInterval')}`,
        },
      ]
      this.cancelReservateFlag = [
        {
          required: true,
          trigger: 'change',
          message: `${window.v.$t('QXZ')} ${window.v.$t('bookingCancel')}`,
        },
      ]
    }
  }
}
export default searchFrom
