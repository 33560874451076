<!--
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2021-07-29 10:13:30
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\service\index.vue
 -->
<template>
  <div class="wrap venueMana">
    <div class="table-container">
      <el-tabs class="tap-top-wrapper taps-card" v-model="form.status">
        <el-tab-pane :label="$t('booking')" name="1">
          <booking-part ></booking-part>
        </el-tab-pane>
        <el-tab-pane :label="$t('ticket')" name="2">
          <ticket-part ></ticket-part>
        </el-tab-pane>
      </el-tabs>
      <!-- <div class="tap-content">
        <booking-part v-show="form.status==='1'"></booking-part>
        <ticket-part v-show="form.status==='2'"></ticket-part>
      </div> -->
    </div>
  </div>
</template>
<script>
import UserSearchClass from './userSearchClass'
import mixin from '@/mixins/index'
import bookingPart from './component/bookingPart.vue'
import ticketPart from './component/ticketPart.vue'

export default {
  components: {
    bookingPart,
    ticketPart,
  },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass('form'), // 实例化一个表单的变量
      rules: new UserSearchClass('rule', this.Language), // 实例化一个表单的规则
    }
  },
  methods: {
    searchData() {},
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.venueMana {
  .table-container {
   height: calc(100vh - 150px);
   overflow: auto;
   margin: 0 auto;
   .tap-top-wrapper {
     margin-top: 20px;
   }
   .tap-content {
      // padding-top: 20px;
      padding-bottom: 50px;
      margin-bottom: 50px;
      background:rgba(255,255,255,1);
      border-radius:4px;
      border:1px solid rgba(228,233,241,1);
    }
 }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
