<template>
  <div class="dialog-container weekBox">
    <el-dialog
      :title="`${isEdit ? '编辑营业时间' :'新增营业时间'}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="400px">
      <el-form ref="form" :model="form" label-width="85px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('week')" prop="week">
              <el-checkbox-group v-model="form.week" size="mini">
                <el-checkbox-button v-for="(item, index) in form.weeks" :label="item.value" :key="index">{{item.text}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('startTime')" prop="startTime">
              <el-select v-model="form.startTime" @change="changeTime">
                <el-option v-for="item in DROPDOWNBOX.BUSINESS_TIME" :key="item.value" :value="item.value" :label="item.label" style="width: 100%"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('endTime')" prop="endTime">
              <el-select v-model="form.endTime" @change="changeTime">
                <el-option v-for="item in DROPDOWNBOX.BUSINESS_TIME" :key="item.value" :value="item.value" :label="item.label" style="width: 100%"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      weeks: [],
      allValue: [], //所有已选的
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
    apis() {
      return apis
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.form = new AddDialogClass('form')
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      this.form.isEdit = this.isEdit
      this.form.editIndex = this.isEdit
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('addSure', this.form)
          this.closeDialog()
        }
      })
    },
    /**
     * @function 时间大小比较
     */
    changeTime() {
      const date1 = Date.parse(`2008-08-08 ${this.form.startTime}`) //'2008-08-08'这个日期随便给
      const date2 = Date.parse(`2008-08-08 ${this.form.endTime}`)
      if (this.form.startTime !== '' && this.form.endTime !== '') {
        if (date1 > date2) {
          this.$message.warning('开始时间必须小于结束时间')
          this.form.endTime = ''
        }
      }
    },
    // 新增时去除已选,
    showData(data) {
      this.form.weeks = [
        { value: '1', text: '一' },
        { value: '2', text: '二' },
        { value: '3', text: '三' },
        { value: '4', text: '四' },
        { value: '5', text: '五' },
        { value: '6', text: '六' },
        { value: '7', text: '日' },
      ]
      if (data.length > 0 && data.length < 7) {
        data.forEach((item) => {
          this.delete(this.form.weeks, item)
        })
      }
    },
    /**
     * @function 所有数据中删除已选择的数据
     * @param {Boolean} selected 外面已选择的数据
     * @param {Boolean} nowData 当前选择到的数据加上
     */
    // 周一到周日，去除除了本身的其他所有数据
    showRemaining(selected, nowData, allData) {
      console.log(nowData)
      console.log(allData)
      this.form.week = allData.week
      this.form.weeks = [
        { value: '1', text: '一' },
        { value: '2', text: '二' },
        { value: '3', text: '三' },
        { value: '4', text: '四' },
        { value: '5', text: '五' },
        { value: '6', text: '六' },
        { value: '7', text: '日' },
      ]
      selected.forEach((item) => {
        this.delete(this.form.weeks, item)
      })
      const weeks = this.form.weeks.concat(nowData)
      this.form = Object.assign({}, this.form, { weeks })
      this.form.weeks.sort(this.compare('value'))
      this.form.startTime = allData.startTime
      this.form.endTime = allData.endTime
    },
    // 数组对象的某个属性进行排序
    compare(property) {
      // eslint-disable-next-line func-names
      return function (a, b) {
        const value1 = a[property]
        const value2 = b[property]
        return value1 - value2
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
}
</style>
