<template>
  <div class="dialog-container weekBox">
    <el-dialog
      :title="`${isEdit ? '编辑票务' : '新增票务'}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-tabs class="taps-card" v-model="form.status">
          <el-tab-pane :label="$t('basicData')" name="1">
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('ticketInfo1') + ':'"
                  prop="ticketInfo"
                  label-width="120px"
                >
                  <el-input
                    clearable
                    v-model="form.ticketInfo"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('deadline1') + ':'"
                  prop="deadline"
                  label-width="120px"
                >
                  <el-date-picker
                    v-model="form.deadline"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('sellingPrice') + ':'"
                  prop="price"
                  label-width="120px"
                >
                  <!-- <el-input clearable v-model="form.price" @input="e => form.price = e < 0.01 ? 0.01 : e" type="number" style="width: 100%">
                      <span slot="suffix">元</span>
                    </el-input> -->
                  <el-input
                    clearable
                    v-model="form.price"
                    @input="
                      (e) => (form.price = e <= 100000 && e >= 0 ? e : 100000)
                    "
                    type="number"
                    style="width: 100%"
                  >
                    <!-- <span slot="suffix">元</span> -->
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('originalPriceTag') + ':'"
                  prop="orgPrice"
                  label-width="120px"
                >
                  <el-input
                    clearable
                    v-model="form.orgPrice"
                    @input="
                      (e) =>
                        (form.orgPrice = e <= 100000 && e >= 0 ? e : 100000)
                    "
                    style="width: 100%"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="使用时间:"
                  prop="useWeek"
                  label-width="120px"
                >
                  <el-select
                    v-model="form.useWeek"
                    multiple
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in weeks"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                      style="width: 100%"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('startTime') + ':'"
                  prop="startTime"
                  label-width="120px"
                >
                  <el-select
                    v-model="form.startTime"
                    @change="changeTime"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in DROPDOWNBOX.BUSINESS_TIME"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                      style="width: 100%"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('endTime') + ':'"
                  prop="endTime"
                  label-width="120px"
                >
                  <el-select
                    v-model="form.endTime"
                    @change="changeTime"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in DROPDOWNBOX.BUSINESS_TIME"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                      style="width: 100%"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('shopShow1') + ':'"
                  prop="onlineShopDisplay"
                  label-width="120px"
                >
                  <el-select
                    v-model="form.onlineShopDisplay"
                    @change="changeTime"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in DROPDOWNBOX.ONLINE_SHOP_DISPLAY"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                      style="width: 100%"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('sportsProjectName') + ':'"
                  prop="sportsProjectCode"
                  label-width="120px"
                >
                  <el-select
                    v-model="form.sportsProjectCode"
                    @change="changeSport($event)"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in sportsProjectList"
                      :key="item.sportsProjectCode"
                      :value="item.sportsProjectCode"
                      :label="item.sportsProjectName"
                      style="width: 100%"
                      @click.native="toSelect(item)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('refundTicket1') + ':'"
                  label-width="120px"
                  prop="returnFlag"
                >
                  <el-switch
                    v-model="form.returnFlag"
                    active-text="否"
                    inactive-text="是"
                    active-value="N"
                    inactive-value="Y"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="票务总数量:"
                  prop="ticketQty"
                  label-width="120px"
                >
                  <el-radio-group v-model="form.ticketQty" @change="ticketChange">
                    <el-radio label="Y">限制</el-radio>
                    <el-radio label="N">不限制</el-radio>
                  </el-radio-group>
                  <el-input-number v-if="form.ticketQty === 'Y'" v-model="form.ticketTotalQty" :min="1" :max="1000000" style="margin: 0 15px 0 50px"></el-input-number>
                  <span v-if="form.ticketQty === 'Y'">张</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="每人限制购买:"
                  prop="purchaseQty"
                  label-width="120px"
                >
                  <el-radio-group v-model="form.purchaseQty" @change="purchaseChange">
                    <el-radio label="Y">限制</el-radio>
                    <el-radio label="N">不限制</el-radio>
                  </el-radio-group>
                  <el-input-number v-if="form.purchaseQty === 'Y'" v-model="form.purchaseLimitQty" :min="1" :max="10" style="margin: 0 15px 0 50px"></el-input-number>
                  <span v-if="form.purchaseQty === 'Y'">张</span>
                </el-form-item>
              </el-col>
              <el-col :span="24" style="margin-bottom: 10px">
                <el-form-item
                  label="支付方式:"
                  prop="payType"
                  label-width="120px"
                >
                  <el-checkbox-group v-model="form.payType">
                    <el-checkbox v-for="item in DROPDOWNBOX.VENUE_PAY_TYPE" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="24" style="margin-bottom: 10px">
                <el-form-item
                  :label="$t('picture') + ':'"
                  prop="photoPaths"
                  label-width="120px"
                >
                  <el-upload
                    class="upload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    name="file"
                    accept=".png,.jpg"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                  >
                    <img
                      v-if="form.photoPaths !== ''"
                      :src="form.photoPaths"
                      alt=""
                      srcset=""
                      width="78"
                      height="78"
                    />
                    <!-- <el-button v-if="form.photoPaths === ''">上传</el-button> -->
                    <img
                      v-if="form.photoPaths === ''"
                      class="image-upload"
                      src="../../../../assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于72*54px，图片格式png/jpg
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                  <el-form-item>
                    <p>图片尺寸不大于100*100px, 图片格式为 png/jpg</p>
                  </el-form-item>
                </el-col> -->
            </el-row>
          </el-tab-pane>
          <el-tab-pane :label="$t('operateProject')" name="2">
            <div class="operation-wrapper">
              <div class="preview-images-wrapper">
                <img
                  class="images"
                  src="../../../../assets/images/ticket_tip.png"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="ticket-right">
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      class="share-writer"
                      :label="$t('title')"
                      prop="shareCopywriter"
                    >
                      <el-input
                        clearable
                        v-model="form.shareCopywriter"
                        style="width: 100%"
                        maxlength="100"
                        type="textarea"
                        resize="none"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item prop="purchaseNote" label-width="0">
                      <div class="edit_container">
                        <!-- <quill-editor v-model="form.purchaseNote" @change="onEditChange" ref="myQuillEditor" class="ql-editor editer" :options="editorOption" @ready="onEditorReady($event)">
                        </quill-editor> -->
                        <quill-edit
                          :detail="form.purchaseNote"
                          @change="onEditChange"
                          :qnLocation="apis.ImageUpload"
                        >
                        </quill-edit>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import mixin from "@/mixins/dialogMixin";
import apis from "@/apis";
import TicketDialogClass from "./ticketDialogClass";
import quillEdit from "@/components/quillEdit.vue";

export default {
  components: { quillEdit },
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  /* eslint-disable */
  data() {
    return {
      sportsProject: {
        sportsProjectCode: "",
        sportsProjectName: "",
      },
      sportsProjectList: [],
      currentName: "",
      flag: false,
      form: new TicketDialogClass("form"), // 实例化一个表单的变量
      rules: new TicketDialogClass("rule", this.Language), // 实例化一个表单的规则
      imageUrl: "",
      editorOption: {
        placeholder: "请输入...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["image", "formula"], //去除video即可
          ],
        },
      },
      weeks: [
        { value: '1', text: '周一' },
        { value: '2', text: '周二' },
        { value: '3', text: '周三' },
        { value: '4', text: '周四' },
        { value: '5', text: '周五' },
        { value: '6', text: '周六' },
        { value: '7', text: '周日' },
      ]
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "venueId"]),
    apis() {
      return apis;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  activated() {
    console.log("bbbbbbbbbbbbb");
    console.log(this.form.sportsProjectName);
  },
  methods: {
    ticketChange(value) {
      if (value === 'N') {
        this.form.ticketTotalQty = ''
      }
    },
    purchaseChange(value) {
      if (value === 'N') {
        this.form.purchaseLimitQty = ''
      }
    },
    onEditChange(value) {
      console.log("onEditChange");
      this.form.purchaseNote = value;
      this.$refs.form.validateField("purchaseNote");
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.form = new TicketDialogClass("form");
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      if (Number(this.form.price) >= 0) {
        console.log("表单=-=-=-=-=", this.form);
        this.form = Object.assign({
          ...this.form,
          sportsProjectName: this.currentName,
        });
        console.log(this.form);
        if(this.form.ticketQty === 'N'){
          this.form.ticketTotalQty = -1
        }
        if(this.form.purchaseQty === 'N'){
          this.form.purchaseLimitQty = -1
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.flag) {
              return;
            }
            this.flag = true;
            const formData = { ...this.form }
            formData.payType = formData.payType.join(',')
            formData.useWeek = formData.useWeek.join(',')
            formData.photoPath = this.imageUrl
            this.$http
              .post(
                this.isEdit
                  ? apis.updateVenueTicketSet
                  : apis.insertVenueTicketSet,
                  formData
                // { ...this.form, ...{ photoPath: this.imageUrl } }
              )
              .then((res) => {
                if (res.data.code === 0) {
                  // 调用关闭方法，不要再写一次
                  this.flag = false;
                  this.closeDialog();
                } else {
                  this.flag = false;
                }
              });
          } else {
            this.changeTap();
          }
        });
      } else {
        // this.$message.error(`请输入大于0的售价`)
      }
    },
    /**
     * 如果没有运营文案没有填写的话，则需要跳转到运营文案，进行tap切换
     */
    changeTap() {
      if (!this.form.shareCopywriter || !this.form.purchaseNote) {
        this.form.status = "2";
      } else {
        this.form.status = "1";
      }
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      console.log("遇到式风格", data);
      this.form.id = data.id;
      this.$http.post(apis.selectVenueTicketSetById, data.id).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...this.form, ...res.data.rows, status: "1" };
          this.currentName = this.form.sportsProjectName;
          this.imageUrl = this.form.photoPath;
          this.form.payType = this.form.payType.split(',')
          this.form.useWeek = this.form.useWeek.split(',')
          if (this.form.ticketTotalQty > 0) {
            this.form.ticketQty = 'Y'
          } else {
            this.form.ticketQty = 'N'
          }
          if (this.form.purchaseLimitQty > 0) {
            this.form.purchaseQty = 'Y'
          } else {
            this.form.purchaseQty = 'N'
          }
        }
      });
    },
    /**
     * @function 时间大小比较
     */
    changeTime() {
      const date1 = Date.parse(`2008-08-08 ${this.form.startTime}`); //'2008-08-08'这个日期随便给
      const date2 = Date.parse(`2008-08-08 ${this.form.endTime}`);
      if (this.form.startTime !== "" && this.form.endTime !== "") {
        if (date1 > date2) {
          this.$message.warning("开始时间必须小于结束时间");
          this.form.endTime = "";
        }
      }
    },
    //
    changeSport(item) {
      // console.log("item", item)
      // this.form.sportsProjectCode = item.sportsProjectCode
      // this.form.sportsProjectName = item.sportsProjectName
    },
    toSelect(item) {
      console.log(item);
      this.currentName = item.sportsProjectName;
    },
    uploadSuccess(res) {
      console.log(res);
      this.form.photoPaths = res.data.url;
      this.imageUrl = res.data.path;
      this.$refs.form.validateField("photoPaths");
    },
    uploadError(res) {
      console.log(res);
    },
    /**
     * @function 文本编辑器
     */
    onEditorReady(editor) {
      console.log(editor);
    },
    /**
     * @function 获取运动项目
     */
    getSportsProject() {
      this.$http
        .get(`${apis.getSportsProjectList}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            console.log("res", res);
            if (res.data && res.data.rows && res.data.rows.length > 0) {
              this.sportsProjectList = res.data.rows;
              // this.sportsProjectCode = res.data.data[0].sportsProjectCode
              // this.sportRadio = res.data.data[0].sportsProjectCode
            } else {
              this.sportsProjectList = [];
              // this.sportRadio = ""
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
  mounted() {
    console.log("this.venueId-----++++++____", this.venueId);
    this.getSportsProject();
  },
};
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.image-upload {
  width: 78px;
  height: 78px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.share-writer {
  // background:rgba(245,245,245,1);
  .el-textarea {
    // width: calc(100% -20px);
    // margin: 10px auto;
  }
}
.operation-wrapper {
  display: flex;
  // flex-direction: row;
  // margin-top: 10px;
  .preview-images-wrapper {
    width: 260px;
    height: 450px;
    margin-right: 14px;
    margin-top: 10px;
    .images {
      width: 100%;
      height: 100%;
    }
  }
  .ticket-right {
    width: 475px;
  }
}
</style>
<style lang="less">
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
@import "../../../../assets/styles/mixin.less";
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .el-upload--picture-card {
    width: 210px;
    height: 147px;
  }
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
}
</style>
