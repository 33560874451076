import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.ticketInfo = ''
      this.deadline = ''
      this.onlineShopDisplay = ''
      this.status = ''
      this.returnFlag = 'Y'
    }
  }
}
export default searchFrom
