<template>
  <div class="dialog-container">
    <el-dialog
      title="入场分享设置"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="400px">
      <el-form ref="form" :model="form" label-width="85px" :rules="rules">
        <el-row>
          <el-col :span="24" v-for="(item, index) in sportItemsList" :key="index">
            <el-form-item :label="item.sportsProjectName" :prop="item.sportsProjectName">
              <el-input v-model="item.sharingLimit" style="width: 100%" @input="e => item.sharingLimit = e <= 10000 && e >= 0? e : 10000" type="number">
                <template slot="prepend">限制</template>
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      sportItemsList: [],
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', 'venueId']),
    apis() {
      return apis
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.form = new AddDialogClass('form')
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      const params = {
        listSharingLimit: this.sportItemsList,
      }
      this.$http.post(apis.updateSharinglimit, params).then((res) => {
        if (res.data.code === 0) {
          this.sportItemsList = res.data.data
          this.$message.success("保存成功")
          this.closeDialog()
        }
      })
    },
    /**
     * @function 查询数据
     */
    getQueryData() {
      this.$http.get(`${apis.getSharinglimit}?venueId=${this.venueId}`).then((res) => {
        if (res.data.code === 0) {
          this.sportItemsList = res.data.data
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
