/*
 * @Author: your name
 * @Date: 2021-07-19 15:11:31
 * @LastEditTime: 2021-08-26 10:10:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\venueMana\service\dialog\addDialogClass.js
 */
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.startTime = ''
      this.endTime = ''
      this.week = []
      this.weeks = [
        { value: '1', text: '一' },
        { value: '2', text: '二' },
        { value: '3', text: '三' },
        { value: '4', text: '四' },
        { value: '5', text: '五' },
        { value: '6', text: '六' },
        { value: '7', text: '日' },
      ]
    } else if (type === 'rule') {
      this.startTime = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('startTime')}`,
          trigger: 'change',
        },
      ]
      this.endTime = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('endTime')}`,
          trigger: 'change',
        },
      ]
      this.week = [
        {
          required: true,
          type: 'array',
          message: `${window.v.$t('QXZ')} ${window.v.$t('week')}`,
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
