class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.status = '1'
      this.ticketInfo = ''
      this.deadline = ''
      this.price = ''
      this.orgPrice = ''
      this.startTime = ''
      this.endTime = ''
      this.onlineShopDisplay = ''
      this.returnFlag = 'Y'
      this.photoPath = ''
      this.photoPaths = ''
      this.shareCopywriter = ''
      this.purchaseNote = ''
      this.sportsProjectName = ''
      this.sportsProjectCode = ''
      this.ticketQty = ''
      this.ticketTotalQty = ''
      this.purchaseQty = ''
      this.purchaseLimitQty = ''
      this.payType = []
      this.useWeek = []
    } else if (type === 'rule') {
      this.photoPaths = [
        {
          required: true,
          message: `请选择图片`,
          trigger: 'change',
        },
      ]
      this.ticketInfo = [
        {
          required: true,
          message: `${window.v.$t('QTX')} ${window.v.$t('ticketInfo1')}`,
          trigger: 'blur',
        },
      ]
      this.deadline = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('deadline1')}`,
          trigger: 'change',
        },
      ]
      this.price = [
        {
          required: true,
          message: `${window.v.$t('QTX')} ${window.v.$t('sellingPrice')}`,
          trigger: 'blur',
        },
      ]
      this.orgPrice = [
        {
          required: true,
          message: `${window.v.$t('QTX')} ${window.v.$t('originalPriceTag')}`,
          trigger: 'blur',
        },
      ]
      this.startTime = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('startTime')}`,
          trigger: 'change',
        },
      ]
      this.endTime = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('endTime')}`,
          trigger: 'change',
        },
      ]
      this.onlineShopDisplay = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('shopShow1')}`,
          trigger: 'change',
        },
      ]
      this.returnFlag = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('refundTicket')}`,
          trigger: 'change',
        },
      ]
      this.photoPath = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('picture')}`,
          trigger: 'change',
        },
      ]
      this.shareCopywriter = [
        {
          required: true,
          message: `${window.v.$t('QTX')} ${window.v.$t('share')}`,
          trigger: 'blur',
        },
      ]
      this.purchaseNote = [
        {
          required: true,
          message: `${window.v.$t('QTX')} ${window.v.$t('shopTips')}`,
          trigger: 'blur',
        },
      ]
      this.sportsProjectCode = [
        {
          required: true,
          message: `${window.v.$t('QXZ')} ${window.v.$t('sportsProjectName')}`,
          trigger: 'change',
        },
      ]
      this.ticketQty = [
        {
          required: true,
          message: '请选择 票务总数量',
          trigger: 'change',
        },
      ]
      this.purchaseQty = [
        {
          required: true,
          message: '请选择 每人限制购买',
          trigger: 'change',
        },
      ]
      this.payType = [
        {
          required: true,
          message: '请选择 支付方式',
          trigger: 'change',
        },
      ]
      this.useWeek = [
        {
          required: true,
          message: '请选择 使用时间',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
