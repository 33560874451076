<template>
  <div class="wrap ticketWrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="120px">
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ticketInfo1')">
                  <el-input  v-model="form.ticketInfo" clearable style="width: 100%"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('deadline1')">
                  <el-date-picker clearable v-model="form.deadline" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" style="width: 100%"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('shopShow1')">
                  <el-select v-model="form.onlineShopDisplay" clearable>
                    <el-option v-for="item in DROPDOWNBOX.ONLINE_SHOP_DISPLAY" :key="item.value" :value="item.value" :label="item.label"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('statusNow1')">
                  <el-select style="width:100%" v-model="form.status" clearable>
                    <el-option v-for="item in DROPDOWNBOX.TICKET_LIST_STATUS" :key="item.value" :value="item.value" :label="item.label"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('refundTicket1')">
                  <el-switch
                    @change="searchData"
                    v-model="form.returnFlag"
                    active-text="否"
                    inactive-text="是"
                    active-value="N"
                    inactive-value="Y">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" style="text-align:right">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- <expand-collapse ref="expCol" @change="chooseFold" @click="expCol =!expCol"></expand-collapse> -->
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight - 120"
				style="width: 100%;margin-right: 0 !important;"
        border
        @sort-change='sort_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <el-table-column
          type="index"
          fixed="left"
          label="序号"
          align="center"
          width="55">
        </el-table-column>
				<el-table-column
					type="selection"
          fixed="left"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
          v-for="item in tableHeadDataList"
          :key="`table_${item.prop}`"
          :show-overflow-tooltip="true"
          :sortable="item.sortable"
					:prop="item.prop"
          :align="item.align"
					:label="item.label"
					:minWidth="item.width ? item.width : ''">
				</el-table-column>
			</el-table>
    </div>
    <div class="page-container">
      <div class="button-containers">
        <el-button round class="btn-round-style" size="mini" v-if="buttonAuth.includes('venueMana:service:add')" @click="changeAddDialog(true)">{{$t('addTicket')}}</el-button>
        <el-button round class="btn-round-style" size="mini" v-if="buttonAuth.includes('venueMana:service:venueEdit')" @click="editData">{{$t('editTicket')}}</el-button>
        <el-button round class="btn-round-style" size="mini" v-if="buttonAuth.includes('venueMana:service:remove')" @click="openDeleteDialog(false)">{{$t('deleteTicket')}}</el-button>
        <el-button round class="btn-round-style" size="mini" v-if="buttonAuth.includes('venueMana:service:modifyStrtus')" @click="openstatusDialog(true)">{{$t('status1')}}</el-button>
        <el-button round class="btn-round-style" size="mini" v-if="buttonAuth.includes('venueMana:service:modifyStrtus')" @click="openstatusDialog(false)">{{$t('status2')}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <ticket-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></ticket-dialog>
    <!-- 删除弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <!-- 上下架弹窗 -->
    <status-dialog ref="statusDialog" :flag="flag" :Language="Language" :show="statusDialog.visible" @closeDialog="changeStatusDialog"></status-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import mixin from '@/mixins/index'
// import expandCollapse from '@/components/expandCollapse.vue'
import TicketPartClass from './ticketPartClass'
import confirmDialog from '@/components/confirmDialog.vue'
import ticketDialog from '../dialog/ticketDialog.vue'
import statusDialog from '../dialog/statusDialog.vue'

export default {
  components: {
    // expandCollapse,
    ticketDialog,
    confirmDialog,
    statusDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new TicketPartClass('form'), // 实例化一个表单的变量
      expCol: true, //伸缩栏
      tableHeadDataList: [
        // {
        //   prop: 'order',
        //   align: 'center',
        //   label: this.$t('order'),
        //   width: '160',
        // },
        {
          prop: 'ticketInfo',
          align: 'center',
          label: this.$t('ticketInfo1'),
          width: '130',
        },
        {
          prop: 'consumptionPeriod',
          align: 'center',
          label: this.$t('consumptionPeriod'),
          width: '160',
        },
        {
          prop: 'price',
          align: 'center',
          label: this.$t('sellingPrice'),
          sortable: 'custom',
          width: '170',
        },
        {
          prop: 'deadline',
          align: 'center',
          label: this.$t('deadline1'),
          sortable: 'custom',
          width: '170',
          minWidth: '180',
        },
        {
          prop: 'orgPrice',
          align: 'center',
          label: this.$t('originalPriceTag'),
          width: '160',
          minWidth: '160',
        },
        {
          prop: 'returnFlag',
          align: 'center',
          label: this.$t('refundTicket1'),
          width: '160',
          minWidth: '160',
        },
        {
          prop: 'onlineShopDisplay',
          align: 'center',
          label: this.$t('shopShow1'),
          width: '160',
          minWidth: '160',
        },
        {
          prop: 'totalSales',
          align: 'center',
          label: this.$t('totalSales'),
          sortable: 'custom',
          width: '160',
          minWidth: '160',
        },
        {
          prop: 'status',
          align: 'center',
          label: this.$t('statusNow1'),
          width: '160',
          minWidth: '160',
        },
      ],
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      statusDialog: {
        visible: false,
      },
      flag: true, // 默认上架的状态
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  mounted() {
    this.searchData()
  },
  methods: {
    // 用来表头排序
    sort_change({ prop, order }) {
      const changeText = {
        ascending: "asc",
        descending: "desc",
      }
      this.form.orderByColumn = order ? prop : null;
      this.form.isAsc = changeText[order]
      this.searchData()
    },
    /**
     * @function 伸缩栏传参
     */
    chooseFold(val) {
      this.expCol = val
    },
    /**
     * @function 查询
     */
    searchData() {
      this.$http.post(apis.selectVenueTicketSet, { ...this.form, venueId: this.venueId }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    // 修改
    editData() {
      if (this.selectedArr.length === 1) {
        this.changeAddDialog(true, true, this.selectedArr.filter(item => item.id)[0])
      } else {
        this.$message.error(this.$t('onlyOne'))
      }
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchData()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array|Boolean} id 选中的ID数组,如果为false，则表示是表格外部的按钮
     */
    openDeleteDialog(id) {
      if (!id) {
        if (this.selectedArr.length === 0) {
          this.$message.warning(this.$t('atLeast'));
        } else {
          this.confirmDialog.data.id = this.selectedArr.map(item => item.id)
          this.changeConfirmDialog(true)
        }
      } else {
        this.confirmDialog.data.id = id
        this.changeConfirmDialog(true)
      }
    },
    /* eslint-disable */
    /**
     * @function 删除确定
     */
    sureConfirmDialog() {
      let selectedIds = []
      this.selectedArr.forEach((item) => {
        selectedIds.push(item.id)
      })
      console.log("sureConfirmDialog -> selectedId", selectedIds)
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.deleteVenueTicketSet, {
        id: selectedIds
      }).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog({
            dialogStatus: false,
            autoSearch: true,
          })
        }
      })
    },
    /**
     * @function 改变删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data = []
        this.searchEvent()
      }
    },
    /**
     * @function 打开上下架弹窗
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    openstatusDialog(flag) {
      if (this.selectedArr.length === 0) {
        this.$message.warning(this.$t('atLeast'));
      } else {
        this.$refs['statusDialog'].id = this.selectedArr.map(item => item.id)
        this.flag = flag
        this.changeStatusDialog(true)
      }
    },
    /**
     * @function 改变上下架弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeStatusDialog(dialogStatus) {
      this.statusDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.searchEvent()
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.table-container {
  // margin-top: 20px;
}
.ticketWrap {
  padding-bottom: 20px;
  background:rgba(255,255,255,1);
  border-radius:4px;
  border:1px solid rgba(228,233,241,1);
  .search-container {
    margin: 0;
    overflow: hidden;
  }
  .page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .button-containers {
    margin-left: 20px;
  }
  
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
