<template>
  <div class="wrap venueMana">
    <el-form ref="form" :model="form" label-width="150px" :rules="rules">
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="sportsProjectName"
            label="运动项目："
            label-width="100px"
          >
            <el-col :span="24">
              <el-row>
                <el-col :span="24">
                  <el-radio-group
                    class="venue-radio-wrapper"
                    @change="onChangeCode($event)"
                    v-model="sportRadio"
                    :max="1"
                  >
                    <el-radio-button
                      class="radio"
                      v-for="item in sportList"
                      :key="item.sportsProjectCode"
                      :label="item.sportsProjectCode"
                      >{{ item.sportsProjectName }}</el-radio-button
                    >
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-col>
          </el-form-item></el-col
        >
        <el-col :span="24">
          <el-form-item
            style="margin-bottom: 17px"
            :label="$t('bookingSetting')"
            prop="reservateFlag"
          >
            <el-switch
              v-model="form.reservateFlag"
              active-text="支持"
              inactive-text="不支持"
              @change="reservateChange"
              active-value="Y"
              inactive-value="N"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('defaultTime')" prop="businessHours">
            <el-table
              v-show="form.businessHours.length > 0"
              v-if="showTable"
              :show-header="false"
              :data="form.businessHours"
              :inline-message="true"
              style="width: 722px"
              border
            >
              <el-table-column width="300px" prop="registerNo" align="left">
                <template slot-scope="scope">
                  <div style="margin-left: 2px">
                    <div
                      circle
                      v-for="(item, index) in scope.row.dates"
                      :key="index"
                      class="book-part-date"
                    >
                      {{ item.text }}
                    </div>
                  </div>
                  <!-- <el-button type="primary" circle v-for="(item, index) in scope.row.dates" :key="index">{{item.text}}</el-button> -->
                </template>
              </el-table-column>
              <el-table-column prop="time" width="220px" align="center">
              </el-table-column>
              <el-table-column width="200px" align="center">
                <template slot-scope="scope">
                  <el-button
                    class="btn-edit"
                    size="mini"
                    v-if="
                      buttonAuth.includes('venueMana:service:reservatEedit')
                    "
                    @click="changeAddDialog(true, true, scope.$index)"
                    icon="el-icon-edit-outline"
                  ></el-button>
                  <el-button
                    class="btn-delete"
                    size="mini"
                    v-if="buttonAuth.includes('venueMana:service:timeRemove')"
                    @click="handleDelete(scope.$index)"
                    icon="el-icon-delete"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              class="btn-add"
              icon="el-icon-circle-plus-outline"
              v-if="buttonAuth.includes('venueMana:service:timeAdd')"
              @click="changeAddDialog(true)"
              >{{ $t("add") }}</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('bookingInterval')" prop="reservateInterval">
            <el-select v-model="form.reservateInterval">
              <el-option
                v-for="item in DROPDOWNBOX.SPACE_RESERVATE_INTERVAL"
                :key="item.value"
                :value="item.value"
                :label="item.label"
                style="width: 100%"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item :label="$t('entryTime')" prop="reservateInterval">
            提前
            <el-input-number v-model="input">
            </el-input-number>
            分钟
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('entryTime')" prop="reservateInterval">
            限制
            <el-input-number v-model="input">
            </el-input-number>
            次
          </el-form-item>
        </el-col> -->
        <el-col :span="24">
          <el-form-item :label="$t('bookingCancel')" prop="cancelReservateFlag">
            <el-switch
              v-model="form.cancelReservateFlag"
              active-text="支持"
              inactive-text="不支持"
              active-value="Y"
              inactive-value="N"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <template v-if="form.cancelReservateFlag === 'Y'">
          <el-col :span="24">
            <el-form-item label="提前" prop="refundAdvenceTime">
              <el-input-number
                v-model="form.refundAdvenceTime"
                :precision="2"
              >
              </el-input-number>
              分钟
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="退款" prop="refundAmountProportion">
              <el-input-number
                v-model="form.refundAmountProportion"
                :min="0"
                :max="100"
                :precision="0"
                disabled
              >
              </el-input-number>
              %
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="单人单天退订" prop="refundLimit">
              <el-input-number
                v-model="form.refundLimit"
                :min="0"
                :precision="0"
              >
              </el-input-number>
              次
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <p class="warning">
      温馨提示：如果您需要设置同一场馆周末和平时价格不一样，或者同一天不同时段有不一样的价格，请点击高级设置
    </p>
    <div class="button-container">
      <router-link
        v-if="buttonAuth.includes('venueMana:service:advanceEedit')"
        :to="
          form.id === null ? 'service' : `/setting?id=${this.sportsProjectCode}`
        "
        ><el-button
          class="default-button"
          :class="{ 'disable-button': form.id === null }"
          :disabled="form.id === null"
          >{{ $t("advancedSetting") }}</el-button
        ></router-link
      >
      <el-button
        v-if="buttonAuth.includes('venueMana:service:reservatEedit')"
        class="default-button"
        @click="saveYes"
        >{{ $t("save") }}</el-button
      >
      <el-button class="default-button" @click="handleTimeSetting(true)"
        >入场时间设置</el-button
      >
      <el-button
        class="default-button"
        style="margin-left: 25px"
        @click="handleShareSetting(true)"
        >入场分享设置</el-button
      >
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
      @addSure="getAddData"
    ></add-dialog>
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
    <!-- 入场时间设置弹窗 -->
    <time-dialog
      ref="timeDialog"
      :Language="Language"
      :show="timeDialog.visible"
      @sureDialog="sureTimeDialog"
      @closeDialog="changeTimeDialog"
    ></time-dialog>
    <share-dialog
      ref="shareDialog"
      :Language="Language"
      :show="shareDialog.visible"
      @sureDialog="sureShareDialog"
      @closeDialog="changeShareDialog"
    ></share-dialog>
    <el-dialog :visible.sync="dialogVisible" class="all-dialog" width="300px">
      <div class="dialog-wrap">确定要重置高级设置的所有信息？</div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="save">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="dialogVisible = false">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BookingPartClass from "./bookingPartClass";
import apis from "@/apis";
import mixin from "@/mixins/index";
import addDialog from "../dialog/addDialog.vue";
import confirmDialog from "./confirmDialog.vue";
import timeDialog from "../dialog/timeDialog.vue";
import shareDialog from "../dialog/shareDialog.vue";

export default {
  components: {
    addDialog,
    confirmDialog,
    timeDialog,
    shareDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new BookingPartClass("form"), // 实例化一个表单的变量
      rules: new BookingPartClass("rule", this.Language), // 实例化一个表单的规则
      addDialog: {
        visible: false,
        isEdit: false,
      },
      weeks: [
        { value: "1", text: "一" },
        { value: "2", text: "二" },
        { value: "3", text: "三" },
        { value: "4", text: "四" },
        { value: "5", text: "五" },
        { value: "6", text: "六" },
        { value: "7", text: "日" },
      ],
      editIndex: -1,
      showTable: true,
      confirmDialog: {
        visible: false,
        type: 8,
        data: {},
      },
      timeDialog: {
        visible: false,
      },
      shareDialog: {
        visible: false,
      },
      flagReservateFlag: "N", //保存全局预约设置
      dialogVisible: false,
      sportList: [],
      sportRadio: "",
      sportsProjectCode: "",
    };
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "venueId"]),
  },
  mounted() {},
  methods: {
    onChangeCode(code) {
      if (code) {
        this.sportsProjectCode = code;
        this.getData(code);
      }
    },
    changeConfirmDialog() {
      this.form.reservateFlag = this.flagReservateFlag;
      this.confirmDialog.visible = false;
    },
    sureConfirmDialog() {
      this.confirmDialog.visible = false;
      this.$router.push("/venueOperation/venue");
    },
    reservateChange(value) {
      console.log("reservateChange -> value", value);
      if (!this.form.flag) {
        this.confirmDialog.visible = true;
      }
    },
    // 入场时间设置
    handleTimeSetting(dialogStatus) {
      if (dialogStatus) {
        this.timeDialog.visible = dialogStatus;
        this.$refs["timeDialog"].getQueryData();
      }
      this.$nextTick(() => {
        this.timeDialog.visible = dialogStatus;
      });
    },
    // 入场分享设置
    handleShareSetting(dialogStatus) {
      if (dialogStatus) {
        this.shareDialog.visible = dialogStatus;
        this.$refs["shareDialog"].getQueryData();
      }
      this.$nextTick(() => {
        this.shareDialog.visible = dialogStatus;
      });
    },
    sureTimeDialog() {
      this.timeDialog.visible = false;
    },
    changeTimeDialog() {
      this.timeDialog.visible = false;
    },
    sureShareDialog() {
      this.shareDialog.visible = false;
    },
    changeShareDialog() {
      this.shareDialog.visible = false;
    },
    searchData() {
      this.$http
        .get(`${apis.getSportsProjectList}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            if (res.data && res.data.rows && res.data.rows.length > 0) {
              this.sportList = res.data.rows;
              this.sportsProjectCode = res.data.rows[0].sportsProjectCode;
              this.sportRadio = res.data.rows[0].sportsProjectCode;
              this.getData(this.sportRadio);
              // if (cb) cb();
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    /**
     * @function 搜索方法
     */
    getData(id) {
      const params = {
        venueId: this.venueId,
        sportsProjectCode: id,
      };
      this.$http.post(apis.selectReservate, { ...params }).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...this.form,
            ...res.data.rows,
            reservateFlag: res.data.rows.reservateFlag
              ? res.data.rows.reservateFlag
              : "N",
            cancelReservateFlag: res.data.rows.cancelReservateFlag
              ? res.data.rows.cancelReservateFlag
              : "Y",
            reservateInterval: res.data.rows.reservateInterval
              ? res.data.rows.reservateInterval
              : "",
            businessHours: res.data.rows.businessHours
              ? res.data.rows.businessHours
              : [],
            dates: res.data.rows.dates ? res.data.rows.dates : [],
            weeks: res.data.rows.weeks ? res.data.rows.weeks : [],
            week: res.data.rows.week ? res.data.rows.week : [],
            refundAdvenceTime:
              res.data.rows.refundAdvenceTime === null
                ? 24
                : res.data.rows.refundAdvenceTime,
            refundAmountProportion:
              res.data.rows.refundAmountProportion === null
                ? 100
                : res.data.rows.refundAmountProportion,
            refundLimit:
              res.data.rows.refundLimit === null
                ? 1
                : res.data.rows.refundLimit,
            status: "1",
          };
          this.flagReservateFlag = this.form.reservateFlag;
          this.form.businessHours.forEach((item, index) => {
            this.form.businessHours[index].week = item.week
              ? item.week.split(",")
              : [];
            this.form.businessHours[
              index
            ].time = `${item.startTime} ~ ${item.endTime}`;
          });
          this.form.businessHours.forEach((item, index) => {
            this.form.businessHours[index].dates = [];
            item.week.forEach((item1) => {
              this.form.businessHours[index].dates = [
                ...this.form.businessHours[index].dates,
                ...this.weeks.filter(itemId => itemId.value === item1),
              ];
            });
          });
          console.log(this.form.businessHours);
          this.form.businessHours.forEach((item) => {
            item.dates.sort(this.compare("value"));
          });
          // 如果预约设置为null 和flag = false 说明是新增的 进来页面不直接弹出提示。如果预约设置有值，flag= false 说明是有场地 是删除了场地
          if (!this.form.flag && res.data.rows.reservateFlag !== null) {
            this.confirmDialog.visible = true;
          }
        }
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     * @param {Object} index 数据下标
     */
    changeAddDialog(dialogStatus, isEdit = false, index) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        let selected = []; // 外面显示的已选星期
        this.form.businessHours.forEach((item) => {
          selected = [...selected, ...item.week];
        });
        console.log(this.form.businessHours);
        if (isEdit) {
          // debugger
          this.editIndex = index;
          this.$refs["addDialog"].showRemaining(
            selected,
            this.form.businessHours[index].dates,
            this.form.businessHours[index],
          );
        } else {
          if (selected.length >= 7) {
            this.$message.warning(this.$t("no-operate"));
            return;
          }
          this.$refs["addDialog"].showData(selected);
        }
        this.addDialog.visible = dialogStatus;
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /* eslint-disable */
    /**
     * @function 保存
     */
    saveYes() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        }
      });
    },
    save() {
      if (!this.form.flag) {
        this.confirmDialog.visible = true;
        this.dialogVisible = false
        return false;
      }
      if (
        this.form.cancelReservateFlag === null &&
        this.form.reservateFlag === null
      ) {
        this.form.cancelReservateFlag = "Y";
        this.form.reservateFlag = "Y";
      }
      console.log(this.form);
      this.form.sportsProjectCode = this.sportsProjectCode;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.businessHours.forEach((item, index) => {
            this.form.businessHours[index].week = item.week.join(",");
          });
          this.$http
            .post(apis.SaveReservate, {
              ...this.form,
              cancelAppointFlag: this.form.cancelReservateFlag,
            })
            .then((res) => {
              this.dialogVisible = false;
              if (res.data.code === 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.getData(this.sportsProjectCode);
              } else if (
                res.data.code === 500 &&
                res.data.msg === "场馆未设置场地信息"
              ) {
                // this.$router.push("/venueOperation/venue")
                // this.dialogVisible = false
                this.form.businessHours.forEach((item, index) => {
                  this.form.businessHours[index].week = item.week.split(",");
                });
              }
            });
        }
      });
    },
    /**
     * @function 拿到添加修改传回的数据
     */
    getAddData(data) {
      data.time = `${data.startTime} ~ ${data.endTime}`;
      // eslint-disable-next-line no-irregular-whitespace
      data.dates = data.weeks.filter((item) => data.week.includes(item.value));
      if (data.isEdit) {
        this.form.businessHours[this.editIndex] = data;
        this.showTable = false;
        this.$nextTick(() => {
          this.showTable = true;
        });
      } else {
        this.form.businessHours.push({ ...data });
      }
      // 重新校验下 默认营业事件 提示下划线
      this.$refs.form.validate();
    },
    /**
     * @function 删除行数
     */
    handleDelete(index) {
      this.form.businessHours.splice(index, 1);
      // 重新校验下 默认营业事件 提示下划线
      this.$refs.form.validate();
    },
    // 数组对象的某个属性进行排序
    compare(property) {
      // eslint-disable-next-line func-names
      return function (a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.venueMana {
  padding-top: 20px;
  padding-bottom: 50px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(228, 233, 241, 1);
  .button-container {
    margin-top: 28px;
    margin-left: 20px;
    .default-button {
      float: none;
      margin-right: 20px;
    }
  }
  .warning {
    color: #e94040;
    margin-left: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
.book-part-date {
  width: 25px;
  // height:22px;
  background: rgba(68, 155, 255, 0.3);
  border: 1px solid rgba(68, 155, 255, 1);
  color: rgba(68, 155, 255, 1);
  border-radius: 50%;
  padding: 2px 4px;
  display: inline-block;
  margin-right: 12px;
  line-height: 18px;
}
.disable-button {
  background: rgb(144, 144, 144) !important;
  border-color: rgb(144, 144, 144) !important;
}
.dialog-wrap {
  display: grid;
  justify-content: center;
  .system-tip {
    color: rgba(36, 45, 65, 1);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }
  .el-icon-warning {
    font-size: 50px;
    margin: 0 auto;
    color: @confirmDialog-icon-color;
  }
  p {
    line-height: 40px;
    text-align: center;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.all-dialog {
  .el-dialog__header {
    border-bottom: 0 !important;
  }
  .el-dialog__footer {
    margin: 30px;
  }
  .el-dialog__headerbtn {
    display: none !important;
  }
}
</style>
